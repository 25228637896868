// Generated by Framer (2263e31)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {tTQ1mYCLd: {hover: true}};

const cycleOrder = ["tTQ1mYCLd"];

const serializationHash = "framer-nYvoO"

const variantClassNames = {tTQ1mYCLd: "framer-v-36kg25"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, lpHFwdZ_T: title ?? props.lpHFwdZ_T ?? "Contact Me", yDKXIjjcE: link ?? props.yDKXIjjcE} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, lpHFwdZ_T, yDKXIjjcE, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "tTQ1mYCLd", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-nYvoO", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={yDKXIjjcE} smoothScroll><motion.a {...restProps} className={`${cx("framer-36kg25", className)} framer-y4qzh0`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"tTQ1mYCLd"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-d8ef37fa-4a75-4982-bd34-1b8648be5b7b, rgb(231, 91, 1))", borderBottomLeftRadius: 60, borderBottomRightRadius: 60, borderTopLeftRadius: 60, borderTopRightRadius: 60, ...style}} variants={{"tTQ1mYCLd-hover": {backgroundColor: "rgb(168, 65, 0)"}}} {...addPropertyOverrides({"tTQ1mYCLd-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy03MDA=", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Contact Me</motion.p></React.Fragment>} className={"framer-1trlhw7"} fonts={["GF;Poppins-700"]} layoutDependency={layoutDependency} layoutId={"xPIprIRDE"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={lpHFwdZ_T} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-nYvoO [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nYvoO .framer-y4qzh0 { display: block; }", ".framer-nYvoO .framer-36kg25 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 16px 32px 16px 32px; position: relative; text-decoration: none; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-nYvoO .framer-1trlhw7 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-nYvoO .framer-36kg25 { gap: 0px; } .framer-nYvoO .framer-36kg25 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-nYvoO .framer-36kg25 > :first-child { margin-left: 0px; } .framer-nYvoO .framer-36kg25 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 159
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"HVtvUP5QN":{"layout":["auto","auto"]}}}
 * @framerVariables {"lpHFwdZ_T":"title","yDKXIjjcE":"link"}
 * @framerImmutableVariables true
 */
const FramertztRz1ahA: React.ComponentType<Props> = withCSS(Component, css, "framer-nYvoO") as typeof Component;
export default FramertztRz1ahA;

FramertztRz1ahA.displayName = "Button";

FramertztRz1ahA.defaultProps = {height: 51, width: 159};

addPropertyControls(FramertztRz1ahA, {lpHFwdZ_T: {defaultValue: "Contact Me", displayTextArea: false, title: "Title", type: ControlType.String}, yDKXIjjcE: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramertztRz1ahA, [{family: "Poppins", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V15vFP-KUEg.woff2", weight: "700"}])